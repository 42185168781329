.fill > * {
  height: 100%;
  width: 100%;
}
.ig-pie-chart {
  height: 100%;
}
.welcome_section {
  width: 100vw;
}
