.main_section {
  padding: 16px 36px;
}
.sub_heading {
  padding: 22px 4px;
}

.imageSection img {
  width: 10em;
  max-height: 10em;
  border-radius: 50%;
}

.main_content .sub_content > span {
  padding: 12px 0;
  font-size: 24px;
  text-transform: capitalize;
}
.review_medicine_name {
  font-weight: 600 !important;
}

.review_title {
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  margin-right: 1rem !important;
}

.review_title_container {
  display: flex;
}
