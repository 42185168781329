.fullWidth {
  width: 100% !important;
}

.imageSection img {
  width: 10em;
  max-height: 10em;
  border-radius: 50%;
}

.main_content .sub_content > span {
  padding: 24px 0;
  font-size: 16px;
  text-transform: capitalize;
}
.mainForm {
  width: 70%;
  margin: 0 auto;
}
