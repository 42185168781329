.faqShow .MuiPaper-elevation1 {
  max-width: 68%;
  margin: 0 auto;
}
/* .faqShow .formFields {
  text-align: center;
} */
.fullWidth {
  width: 100% !important;
}
