.dialogue_root .MuiDialog-container {
  opacity: 0.4 !important;
}
.dropeZone {
  padding: 16px;
  /* min-height: 220px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 2px dashed;
}

.dropeZone p {
  width: 100%;
  text-align: center;
}
