.APEchart .title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  /* margin-bottom: 36px; */
}
.APEchart .chart-section {
  padding: 22px !important;
  max-height: 1450px;
}

.apexcharts-legend {
  height: 100%;
}
.apexcharts-legend-text {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
