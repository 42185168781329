.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}
#main-content {
  padding-left: 24px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.button {
  width: 100% !important;
}
.filterClass {
  margin-bottom: 32px;
}
/* .MuiTableCell-head:last-child{
text-align: center;
} */

iframe {
  z-index: 0 !important;
  display: none;
}

.RaMenuItemCategory-popoverPaper-52 {
  min-height: auto !important;
  min-width: 4% !important;
  padding-right: 8px !important;
  background-color: #fff !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}
